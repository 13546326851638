import React from "react";
import { Link } from "@quintype/components";

import "./tags.m.css";

const Tags = ({ story }) => {
  const tags = story.tags || [];

  return (
    <div styleName="tag-wrapper">
      {tags.map((tag, index) => (
        <Link styleName="tag" href={`/tags/${tag.slug}`} key={index}>
          {tag.name}
        </Link>
      ))}
    </div>
  );
};

export default Tags;
