import React, { useEffect, useState } from "react";
import { array, object } from "prop-types";
import get from "lodash/get";
import { ClientSideOnly, ResponsiveImage } from "@quintype/components";

import { SvgIconHandler } from "../svg-icon-hadler";
import { getAAuthor } from "../../helper/api";

import "./author.m.css";

const AuthorAvatar = ({ author }) => {
  const name = author.name;
  const avatarURL = author["avatar-s3-key"];
  const authorAvatarUrl = author["avatar-url"];
  let imageView = null;

  if (avatarURL) {
    imageView = (
      <ResponsiveImage styleName="author-img" slug={avatarURL} aspectRatio={[1, 1]} alt={name.substring(1)} />
    );
  } else if (authorAvatarUrl) {
    imageView = <img styleName="author-img" src={authorAvatarUrl} alt={name.substring(1)} />;
  } else {
    imageView = (
      <div styleName="default-author-img">
        <SvgIconHandler type="authoruser" />
      </div>
    );
  }

  return <ClientSideOnly>{imageView}</ClientSideOnly>;
};

AuthorAvatar.propTypes = {
  author: object
};

const Author = ({ author, lastUpdatedDateAndTime, isPVExclusive, isLastAuthor, index }) => {
  const { slug, name, id } = author;
  const [authorDetails, setAuthorDetails] = useState({});
  const [isMobile, setMobile] = useState(false);
  const authorRoles = [
    get(authorDetails, ["metadata", "is_DHNS"], ""),
    get(authorDetails, ["metadata", "is_DH Web Desk"], "")
  ];

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  useEffect(() => {
    async function fetchAuthor() {
      await getAAuthor(id).then(({ author }) => setAuthorDetails(author));
    }
    fetchAuthor();
  }, []);

  return (
    <>
      <div styleName="author-wrapper">
        <div styleName="author-avatar">
          <AuthorAvatar author={author} />
        </div>
        <div styleName={`${isPVExclusive ? "exclusive-timestamp" : "name-time-wrapper"}`}>
          <div styleName="author-text-wrapper">
            <div styleName="author-name">
              <a href={`/author/${slug}`}>{name}</a>
            </div>
            {authorRoles.map((role, index) =>
              ["yes", "Yes", "true", "True", "t", "T"].includes(role) ? (
                <React.Fragment key={index}>
                  <div styleName="small-line"></div>
                  <p styleName="dh-news">{index === 0 ? "DHNS" : "DH Web Desk"}</p>
                </React.Fragment>
              ) : null
            )}
          </div>

          {isLastAuthor && isPVExclusive && !isMobile ? (
            <div styleName={`timestamp ${isPVExclusive ? "ex-timestamp" : ""}`}>
              <div styleName="date">Last Updated : {lastUpdatedDateAndTime} IST</div>
            </div>
          ) : null}
        </div>
      </div>

      {isLastAuthor && isMobile ? (
        <div styleName="timestamp">
          <div styleName="date">Last Updated : {lastUpdatedDateAndTime} IST</div>
        </div>
      ) : null}
      {index == 0 && !isMobile && !isPVExclusive ? (
        <div styleName="timestamp">
          <div styleName="date">Last Updated : {lastUpdatedDateAndTime} IST</div>
        </div>
      ) : null}
    </>
  );
};

Author.propTypes = {
  author: object
};

const Authors = ({ authors, lastUpdatedDateAndTime, isPVExclusive }) => {
  return (
    <div styleName="authors-wrapper">
      {authors &&
        authors.map((author, index) => {
          return (
            <div key={index}>
              <Author
                author={author}
                lastUpdatedDateAndTime={lastUpdatedDateAndTime}
                isPVExclusive={isPVExclusive}
                isLastAuthor={index === authors.length - 1}
                index={index}
              />
            </div>
          );
        })}
    </div>
  );
};

Authors.propTypes = {
  authors: array
};

export default Authors;
