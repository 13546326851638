import React from "react";
import { Link } from "@quintype/components";
import "./follow-us.m.css";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import FollowUsGoogleIcon from "../../../atoms/icons/followus-google-icon";

function FollowUs() {
  const url =
    "https://news.google.com/publications/CAAiEI68v2DwwAsSi23iz1Ps7aAqFAgKIhCOvL9g8MALEott4s9T7O2g?ceid=IN:en&oc=3&hl=en-IN&gl=IN";
  const whatsapp_url = "https://www.whatsapp.com/channel/0029Va4ifN6AYlULZASc7V3S";
  return (
    <div styleName="wrapper">
      <p styleName="follow-us">Follow Us :</p>
      <Link href={whatsapp_url} target="_blank">
        <SvgIconHandler type="followuswhatsapp" />
      </Link>
      <Link href={url} target="_blank">
        <FollowUsGoogleIcon />
      </Link>
    </div>
  );
}

export default FollowUs;
