import React from "react";
import { Link } from "@quintype/components";

import AppStore from "../../atoms/app-store";
import GooglePlay from "../../atoms/google-play";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

import "./subscribe-card.m.css";
import FollowUsGoogleIcon from "../../atoms/icons/followus-google-icon";

const SOCIAL_MEDIA_CONTENT = [
  { link: "https://t.me/DeccanHeraldNews", icon: "bottomtelegram" },
  { link: "https://www.facebook.com/deccanherald/", icon: "facebookbottom" },
  { link: "https://twitter.com/deccanherald", icon: "twitterbottom" },
  { link: "https://www.instagram.com/deccanherald/", icon: "instagrambottom" },
  { link: "https://www.youtube.com/channel/UCHDXXHPrz-1GVbK_qk82hBQ", icon: "youtubebottom" }
];
const google_url =
  "https://news.google.com/publications/CAAiEI68v2DwwAsSi23iz1Ps7aAqFAgKIhCOvL9g8MALEott4s9T7O2g?ceid=IN:en&oc=3&hl=en-IN&gl=IN";
const whatsapp_url = "https://www.whatsapp.com/channel/0029Va4ifN6AYlULZASc7V3S";

const SubscribeCard = ({ story }) => {
  return (
    <div styleName="wrapper">
      <div styleName="subscribe-left">
        <span>
          <h2 className="hide-mobile-tab">Follow us on :</h2>
          <h2 className="hide-desktop-tab"> Follow Us</h2>
        </span>
        <div styleName="subscribe-social-icons">
          <Link href={whatsapp_url} target="_blank">
            <SvgIconHandler type="followuswhatsapp" height="32px" width="32px" />
          </Link>
          <Link styleName="google-icon" href={google_url} target="_blank">
            <FollowUsGoogleIcon height="32px" width="32px" isSubscribeCard />
          </Link>
          {SOCIAL_MEDIA_CONTENT?.map((item, index) => (
            <Link href={item.link} key={index} target="_blank">
              <SvgIconHandler type={item.icon} height="32px" width="32px" />
            </Link>
          ))}
        </div>
      </div>
      <div styleName="subscribe-right">
        <a
          href="https://apps.apple.com/us/app/deccan-herald-news/id1511179557"
          target="_blank"
          styleName="app-link"
          rel="noreferrer"
        >
          <AppStore height="46" width="155" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.tpml.dh"
          target="_blank"
          styleName="app-link"
          rel="noreferrer"
        >
          <GooglePlay height="45" width="153" />
        </a>
      </div>
    </div>
  );
};

export default SubscribeCard;
