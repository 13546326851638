import React from "react";
import get from "lodash/get";

import StarRating from "../../../atoms/star-rating";

import "./movie-review.m.css";

const MovieReviewView = ({ story }) => {
  const MetaData = get(story, ["metadata"]);
  const movieName = get(MetaData, ["movie-name"], "");
  const movieCast = get(MetaData, ["movie-casting"], "");
  const movieDirector = get(MetaData, ["movie-director"], "");
  const rating = get(MetaData, ["movie-rating"], "");
  const releaseYear = get(MetaData, ["release-year"], "");
  const movieDuration = get(MetaData, ["movie-duration"], "");

  if (
    movieName === "" &&
    movieCast === "" &&
    movieDirector === "" &&
    rating === "" &&
    releaseYear === "" &&
    movieDuration === ""
  ) {
    return null;
  }

  return (
    <div styleName="wrapper">
      <div styleName="text-and-rating">
        <div styleName="title-wrapper">
          {movieName.length > 0 && <div styleName="title">{movieName}</div>}
          <div styleName="date">
            {releaseYear.length > 0 && <span styleName="year">{releaseYear}</span>}
            {movieDuration.length > 0 && <span styleName="dot" />}
            {movieDuration.length > 0 && <span styleName="duration">{movieDuration}</span>}
          </div>
        </div>
        {rating.length > 0 && <StarRating rating={rating} />}
      </div>
      {movieDirector.length > 0 && (
        <div styleName="details">
          <span styleName="entity-name">Director:</span>
          <span styleName="entity-value">{movieDirector}</span>
        </div>
      )}
      {movieCast.length > 0 && (
        <div styleName="details">
          <span styleName="entity-name">Cast:</span>
          <span styleName="entity-value">{movieCast}</span>
        </div>
      )}
    </div>
  );
};

export default MovieReviewView;
