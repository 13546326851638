/* eslint-disable react/no-unknown-property */
import React, { useEffect } from "react";
import { StoryElement } from "@quintype/components";
import { object, bool, number, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import YouTube from "react-youtube";
import getYouTubeID from "get-youtube-id";

import StoryHeader from "../../story-header";

import "./video-header-block.m.css";

const DmPlayer = ({ story, element }) => {
  useEffect(() => {
    const dmscript = document.createElement("script");
    dmscript.id = "dailymotion-script";
    dmscript.src = "https://srvr.dmvs-apac.com/v2/dm-ce.min.js";
    dmscript.async = true;
    document.body.appendChild(dmscript);

    return () => document.body.contains(dmscript) && document.body.removeChild(dmscript);
  }, []);

  const videoId = get(element, ["metadata", "video-id"], null);
  const metaKeywords = get(story, ["seo", "meta-keywords"], []);

  return (
    <>
      {videoId && (
        <div
          className="dm-player"
          playerId="x1u2p"
          sort="relevance"
          owners="deccanherald"
          customParams={metaKeywords}
          videoId={videoId}
        />
      )}
    </>
  );
};

DmPlayer.propTypes = {
  story: object,
  publisherName: string,
  element: object
};

const YoutubePlayer = ({ element, index }) => {
  const options = {
    playerVars: {
      autoplay: 0,
      mute: 1,
      muted: 1,
      playsinline: 1
    }
  };

  const onReady = (e) => {
    e.target.playVideo();
  };

  return <YouTube videoId={getYouTubeID(element.url)} opts={options} onReady={onReady} />;
};

YoutubePlayer.propTypes = {
  element: object,
  index: number
};

const VideoHeaderBlock = ({ story, publisherName, primaryVideo, isReview, index }) => {
  let renderTemplates = {};

  if (primaryVideo && primaryVideo.subtype === "dailymotion-video") {
    renderTemplates = { "dailymotion-video": DmPlayer };
  } else if (primaryVideo && primaryVideo.type === "youtube-video") {
    renderTemplates = { "youtube-video": YoutubePlayer };
  }
  return (
    <div styleName="wrapper">
      <div styleName="story-video">
        {primaryVideo && (
          <StoryElement
            element={primaryVideo}
            story={story}
            publisherName={publisherName}
            renderTemplates={renderTemplates}
            index={index}
          />
        )}
      </div>
      <StoryHeader story={story} showHeroImage={false} />
    </div>
  );
};

VideoHeaderBlock.propTypes = {
  story: object,
  primaryVideo: object,
  isReview: bool,
  index: number,
  publisherName: string
};

const mapStateToProps = (state) => ({
  publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null)
});

export default connect(mapStateToProps, null)(VideoHeaderBlock);
