import React from "react";
import { object, number } from "prop-types";

import StoryHeader from "../story-header";
import StoryElements from "../story-elements";
import StorySideBar from "../story-sidebar";

import "./embed.m.css";

import StorySeparator from "../../atoms/story-separator";

const EmbedStoryTemplate = ({ story, index }) => {
  return (
    <>
      {index > 0 && <StorySeparator />}
      <div styleName="story-wrapper">
        <div className="story-section">
          <div>
            <StoryHeader story={story} isEmbed={true} showHeroImage={false} />
            <StoryElements story={story} cards={story.cards} index={index} isEmbed={true} />
          </div>
        </div>

        <div className="side-story-section">
          <StorySideBar index={index} story={story} />
        </div>
      </div>
    </>
  );
};

EmbedStoryTemplate.propTypes = {
  story: object,
  index: number
};

const EmbedStory = (props) => {
  return <EmbedStoryTemplate story={props.story} index={props.index} />;
};

EmbedStory.propTypes = {
  story: object,
  index: number
};

export default EmbedStory;
