import React from "react";
import { Link } from "@quintype/components";

import Live from "../../../atoms/live";

import "./live-widget.m.css";

const parseTitleText = (data) => {
  const titles = data?.map((item) => {
    const titleElement = item["story-elements"]?.find((element) => element?.type === "title");
    return titleElement ? titleElement.text : null;
  });
  return titles;
};

const LiveWidget = ({ story }) => {
  const titleTexts = story && parseTitleText(story?.cards);

  return titleTexts ? (
    <Link href={`/${story.slug}`}>
      <div styleName="wrapper">
        <div styleName="heading">
          <Live />
          {story.headline}
        </div>
        {titleTexts?.length && titleTexts[0] ? (
          <div styleName="linked-stories">
            {titleTexts?.slice(0, 4).map((text, idx) =>
              text ? (
                <div styleName="linked-story" key={idx}>
                  <span>{text}</span>
                </div>
              ) : null
            )}
          </div>
        ) : null}
      </div>
    </Link>
  ) : null;
};

export default LiveWidget;
