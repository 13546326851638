import React from "react";
import { object, array, string, bool } from "prop-types";

import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import { modifySectionNames } from "../../../atoms/section-breadcrum";

import "./bredcrums.m.css";

const BredcrumHtml = ({ item, type, story, isAmpStory }) => {
  let url = "";
  Number(item[item.length - 1]) && item.pop();

  const removeExtraString = (secname) => {
    let str = "";
    if (secname.includes("-")) {
      const sections = secname.split("-");
      sections.forEach((item) => (str += item + " "));
      str = str.charAt(0) + str.substring(1);
    } else {
      str = secname.charAt(0) + secname.substring(1);
    }
    return str;
  };

  return (
    <>
      {item.map((secname, i) => {
        let sectionName;
        const ifModifySectionExists = modifySectionNames.find((item) => item.sectionSlug === secname);
        if (ifModifySectionExists) {
          sectionName = ifModifySectionExists.sectionName;
        } else {
          sectionName = removeExtraString(decodeURI(secname));
        }
        if (item.length - 1 !== i) {
          url += `/${secname}`;
        }

        return (
          <React.Fragment key={i}>
            {item.length > 1 && item.length - 1 > i ? (
              <a key={i} href={url}>
                <span styleName="item">{sectionName}</span>
                {isAmpStory ? (
                  <svg width="8px" height="8px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L9.70711 19.7071C9.31658 20.0976 8.68342 20.0976 8.29289 19.7071C7.90237 19.3166 7.90237 18.6834 8.29289 18.2929L14.5858 12L8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289Z"
                      fill="#000000"
                    />
                  </svg>
                ) : (
                  <SvgIconHandler type="bredrightarrow" height="8px" width="8px" />
                )}
              </a>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

BredcrumHtml.propTypes = {
  item: array,
  isAmpStory: bool
};

const Bredcrum = ({ initialPath, type, story, isAmpStory = false }) => {
  let path = "";
  const name = `bredcrum-${type}`;

  if (initialPath.includes("#")) {
    const lastIndex = initialPath.indexOf("#");
    path = initialPath.slice(1, lastIndex);
  } else {
    path = initialPath.slice(1, initialPath.length);
  }

  const section = path.split("/");

  return (
    <div styleName="wrapper" className="breadcrum">
      <a href="/">
        <span styleName="item">Home</span>
        {isAmpStory ? (
          <svg width="8px" height="8px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L9.70711 19.7071C9.31658 20.0976 8.68342 20.0976 8.29289 19.7071C7.90237 19.3166 7.90237 18.6834 8.29289 18.2929L14.5858 12L8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289Z"
              fill="#000000"
            />
          </svg>
        ) : (
          <SvgIconHandler type="bredrightarrow" height="8px" width="8px" />
        )}
      </a>
      <BredcrumHtml item={section} story={story} type={type} isAmpStory={isAmpStory} />
    </div>
  );
};

Bredcrum.propTypes = {
  initialPath: string,
  type: string,
  story: object,
  isAmpStory: bool
};

export default Bredcrum;
