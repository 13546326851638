import React, { useState, useRef, useEffect } from "react";

import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { generateTokenForCommenting } from "../../../helper/utils";
import { setCommentsToken } from "../../../store/actions";

import "./comments-modal.m.css";

const CommentsModal = ({ isOpen, onClose, story, setModalOpen }) => {
  const dispatch = useDispatch();
  const ssoEndpoint = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "sso_endpoint"], null)
  );
  const modalClassName = isOpen ? "modal open" : "modal";
  const modalRef = useRef(null);
  const commentsToken = useSelector((state) => get(state, ["user", "commentsToken"], ""));
  const [accessToken, setAccessToken] = useState(commentsToken || "");
  const userdetails = useSelector((state) => get(state, ["user", "user"], {}));
  const coralRootUrl = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "coral", "root_url"], null)
  );
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const coralUserDetails = {
    user: {
      id: userdetails?.userData?.id || "",
      email: userdetails?.userData?.emails?.[0] || "",
      username: userdetails?.metadata?.displayName || userdetails?.userData?.emails?.[0]?.split("@")?.[0] || ""
    }
  };

  useEffect(() => {
    const isCommentsModalOpen = Boolean(localStorage.getItem("isCommentsModalOpen"));
    const hasLocalTokens = localStorage.getItem("access_token");
    if (
      isOpen &&
      coralRootUrl &&
      !scriptLoaded &&
      (isCommentsModalOpen ? (hasLocalTokens ? accessToken.length > 0 : true) : true)
    ) {
      (function () {
        var d = document,
          s = d.createElement("script");
        s.src = `${coralRootUrl}/assets/js/embed.js`;
        s.async = false;
        s.defer = true;
        s.onload = function () {
          setScriptLoaded(true);
          var embed = Coral.createStreamEmbed({
            id: "coral_thread",
            autoRender: true,
            rootURL: coralRootUrl,
            storyID: story?.id,
            storyURL: story?.url,
            containerClassName: "coral-wrapper",
            events: function (events) {
              events.on("loginPrompt", function () {
                localStorage.setItem("isCommentsModalOpen", true);
                if (window) {
                  window.dataLayer.push({
                    event: "SSO_Page_Open"
                  });
                }
                if (userdetails?.userData && coralUserDetails.user.email.length === 0) {
                  //If user logged in with phone number we are redirecting user to update email from SSO platfrom
                  location.href = `${ssoEndpoint}/auth/change_email?redirectUrl=${
                    window.location.href.split("?")[0]
                  }&emailRequired=true&signupMessage=Please enter your email address to start commenting`;
                } else {
                  // Redirecting user to central authentication page if user not logged in with email flag as true
                  location.href = `${ssoEndpoint}/auth?redirectUrl=${
                    window.location.href.split("?")[0]
                  }&emailRequired=true&signupMessage=Please log in with your email address to start commenting`;
                }
              });
            }
          });
          if (accessToken.length > 0) {
            embed.login(accessToken);
          }
        };
        (d.head || d.body).appendChild(s);
        return () => {
          (d.head || d.body).removeChild(s);
        };
      })();
    }
  }, [accessToken, coralRootUrl, userdetails, isOpen, scriptLoaded]);

  useEffect(() => {
    setModalOpen(Boolean(localStorage.getItem("isCommentsModalOpen")));
  }, []);

  useEffect(() => {
    if (window && isOpen) {
      window.dataLayer.push({
        event: "Commenting_Modal_Open"
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const clikedOutsideModal = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalOpen(false);
        localStorage.removeItem("isCommentsModalOpen");
      }
    };
    document.addEventListener("mousedown", clikedOutsideModal);
    return () => document.removeEventListener("mousedown", clikedOutsideModal);
  }, [modalRef]);

  useEffect(() => {
    if (localStorage.getItem("commentsToken")) {
      setAccessToken(localStorage.getItem("commentsToken"));
    } else if (userdetails?.userData && coralUserDetails?.user?.email.length > 0 && accessToken === "") {
      generateTokenForCommenting(coralUserDetails)
        .then((res) => {
          setAccessToken(res?.token);
          localStorage.setItem("commentsToken", res?.token);
          dispatch(setCommentsToken(res?.token));
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (document.querySelector("#comments-modal")) {
      document
        .querySelector("#comments-modal")
        .addEventListener("scroll", () => window.dispatchEvent(new Event("resize")), { passive: true });
    }
  }, [userdetails]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isOpen]);

  return (
    <div styleName={modalClassName} ref={modalRef} id="comments-modal">
      <div styleName="modal-content">
        <div styleName="comments-count">
          <div>
            <span styleName="comments-text">Comments</span>
            <span
              class="coral-count"
              data-coral-notext="true"
              data-coral-url={story.url}
              data-coral-id={story.id}
            ></span>
          </div>
          <button styleName="close-button" onClick={onClose}>
            <SvgIconHandler type="close" iconStyle={{ color: "#000" }} />
          </button>
        </div>
        <div id="coral_thread"></div>
      </div>
    </div>
  );
};

export default CommentsModal;
