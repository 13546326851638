import React from "react";

const TriangleArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#FFF"
          d="m14.932 9-11.319-7.323a2 2 0 0 0-3.086 1.68v14.647a2 2 0 0 0 3.086 1.679l11.319-7.324a2 2 0 0 0 0-3.358z"
        />
      </g>
    </svg>
  );
};

export default TriangleArrow;
