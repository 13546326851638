import React from "react";
import "./story-separator.m.css";

function StorySeparator() {
  return (
    <div styleName="next-story">
      <p styleName="next-story-content">ಮುಂದಿನ ಲೇಖನ</p>
      <div styleName="triangle-down" />
    </div>
  );
}

export default StorySeparator;
