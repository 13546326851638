import React from "react";
import { object, number } from "prop-types";

import StoryHeader from "../story-header";
import Tags from "../story-elements/tags";
import StorySideBar from "../story-sidebar";
import PanchangaView from "../story-elements/panchanga";

import "./panchanga-story.m.css";

const PanchangaStoryTemplate = ({ story, index }) => {
  return (
    <div styleName="story-wrapper">
      <div className="story-section">
        <div>
          <StoryHeader story={story} />
          <PanchangaView story={story} />
          <Tags story={story} />
        </div>
      </div>

      <StorySideBar index={index} story={story} />
    </div>
  );
};

PanchangaStoryTemplate.propTypes = {
  story: object,
  index: number
};

const PanchangaStory = (props) => {
  return <PanchangaStoryTemplate story={props.story} index={props.index} />;
};

PanchangaStory.propTypes = {
  story: object,
  index: number
};

export default PanchangaStory;
