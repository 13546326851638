/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { object, number } from "prop-types";

import { AdContainer } from "../../ads";

import CardImage from "../../atoms/cardimage";
import StoryHeader from "../story-header";
import StorySideBar from "../story-sidebar";

import "./cartoon.m.css";
import StorySeparator from "../../atoms/story-separator";
import StoryElements from "../story-elements";
import useAdType from "../../helper/useAdType";

const CartoonStoryTemplate = ({ story, index }) => {
  const [isMobile, setMobile] = useState(false);
  const adType = useAdType();

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  return (
    <>
      {index > 0 && <StorySeparator />}
      <div styleName="story-wrapper">
        <div className="story-section">
          <div>
            <StoryHeader story={story} showHeroImage={false} />
            <CardImage
              story={story}
              aspectRatio={[9, 16]}
              imageType={"image9x16"}
              renderedWidth={720}
              widths={[700]}
              showVideoIcon={true}
            />
            <StoryElements story={story} cards={story.cards.slice(1)} index={index} />
            {isMobile ? (
              <div styleName="ad-wrapper" className="hide-desktop">
                <AdContainer
                  AdClassName="standard-336x280"
                  id={adType === "izooto" ? "div-gpt-ad-1711971869798-0" : "div-gpt-ad-1680157824963-0"}
                  mobile={adType === "izooto" ? "DH_MWeb_AT_Top_ym" : "DH_MWeb_AT_Top"}
                  isExternalAdPath={adType === "izooto" ? true : false}
                />
              </div>
            ) : (
              <div styleName="ad-wrapper" className="hide-mobile">
                <AdContainer
                  AdClassName="standard-970x90"
                  id="div-gpt-ad-1680156752712-0"
                  desktop="DH_Desktop_AT_LB_Bottom"
                />
              </div>
            )}
          </div>
        </div>
        <div className="side-story-section">
          <StorySideBar index={index} story={story} />
        </div>
      </div>
    </>
  );
};

CartoonStoryTemplate.propTypes = {
  story: object,
  index: number
};

const CartoonStory = (props) => {
  return <CartoonStoryTemplate story={props.story} index={props.index} />;
};

CartoonStory.propTypes = {
  story: object,
  index: number
};

export default CartoonStory;
