import React from "react";
import { object, number, bool } from "prop-types";

import StoryElements from "../story-elements";
import StorySideBar from "../story-sidebar";
import VideoHeaderBlock from "./video-header-block";

import "./video.m.css";
import StorySeparator from "../../atoms/story-separator";

const getPrimaryVideo = (cards) => {
  const headerVideo = [];
  cards.forEach((card) => {
    card["story-elements"].forEach((element) => {
      if (
        element.type === "youtube-video" ||
        element.subtype === "dailymotion-video" ||
        element.subtype === "dailymotion-embed-script"
      ) {
        headerVideo.push(element);
      }
    });
  });
  return headerVideo[0];
};

const getCards = (cards, id) => {
  let storyCards = [];
  cards.forEach((card) => {
    const newCard = Object.assign({}, card);
    let elements = [];
    card["story-elements"].map((element) => {
      if (element.id !== id) {
        elements = [...elements, element];
      }
    });
    newCard["story-elements"] = elements;
    storyCards = [...storyCards, newCard];
  });
  return storyCards;
};

const VideoStory = ({ story, index, isWebView = false }) => {
  let primaryVideo = null;
  let cards = story.cards;

  const isReview = story["story-template"] === "review";

  if (!isReview || isWebView) {
    primaryVideo = getPrimaryVideo(story.cards);
    cards = primaryVideo ? getCards(story.cards, primaryVideo.id) : story.cards;
  }

  return isWebView ? (
    <StoryElements story={story} cards={cards} index={index} isImmersiveStory={true} />
  ) : (
    <>
      {index > 0 && <StorySeparator />}
      <div styleName="story-wrapper">
        <div className="story-section">
          <div>
            <VideoHeaderBlock story={story} primaryVideo={primaryVideo} isReview={isReview} index={index} />
            <StoryElements story={story} cards={cards} index={index} />
          </div>
        </div>
        <div className="side-story-section hide-mobile">
          <StorySideBar index={index} story={story} />
        </div>
      </div>
    </>
  );
};

VideoStory.propTypes = {
  story: object,
  index: number,
  isWebView: bool
};

export default VideoStory;
