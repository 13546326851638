import React, { useEffect, useState } from "react";
import { Subheadline } from "@quintype/arrow";
import TruncatedText from "../../atoms/truncated-text";
import get from "lodash/get";

import HeroImage from "./hero-image";
import AuthorAndTimeStamp from "./author-and-timestamp";
import EmbedElementCard, { getPrimaryEmbed } from "./embed-element";
import Live from "../../atoms/live";

import "./story-header.m.css";

const StoryHeader = ({ story, isPodCast, showHeroImage, isSponsoredStory, isLiveBlog, isEmbed, closeLiveBlog }) => {
  const isClosed = get(story, ["metadata", "is-closed"], false);
  const customKicker = get(story, ["metadata", "story-attributes", "customkicker"], "");
  const embedElement = getPrimaryEmbed(story);
  const isPodcastType = story["story-template"] === "podcast";

  return (
    <div styleName="wrapper">
      {isPodCast && <EmbedElementCard story={story} />}
      {customKicker.length > 0 ? (
        <div styleName={`custom-kicker ${isPodcastType ? "for-podcast" : ""}`}>{customKicker}</div>
      ) : null}
      <div styleName={isLiveBlog && !closeLiveBlog ? "live-blog-header-h1-wrapper" : "header-h1-wrapper"}>
        <h1>
          {isLiveBlog && !isClosed && !closeLiveBlog && <Live />}
          {story.headline}
        </h1>
      </div>
      {isLiveBlog && !closeLiveBlog ? (
        <TruncatedText story={story} />
      ) : (
        <Subheadline lineClamp={10} story={story} clazzName="sub-headline" />
      )}
      <div
        className={`author-resizer-wrapper ${!isSponsoredStory ? "author-resizer-height" : ""}`}
        id="author-resizer-wrapper"
      >
        <AuthorAndTimeStamp story={story} isSponsoredStory={isSponsoredStory} />
      </div>
      {isEmbed && embedElement ? <EmbedElementCard story={story} /> : null}
      {showHeroImage ? <HeroImage story={story} /> : null}
    </div>
  );
};

StoryHeader.defaultProps = {
  isPodcast: false,
  isSponsoredStory: false,
  showHeroImage: true,
  isLiveBlog: false,
  isEmbed: false
};

export default StoryHeader;
