import React from "react";
import { func, string } from "prop-types";

import "./read-more-button.m.css";

const ReadMoreButton = ({ text, onClick, className }) => {
  return (
    <button onClick={onClick} styleName="read-more-button" className={className}>
      {text}
    </button>
  );
};

ReadMoreButton.propTypes = {
  text: string,
  className: string,
  onClick: func
};

export default ReadMoreButton;
