import React from "react";
import { StoryElement } from "@quintype/components";
import "./promotional-message.m.css";

function PromotionalMessage({ story }) {
  const cards = story.cards;

  const promotionalElement = cards?.map((card) =>
    card["story-elements"]?.filter((element) => element?.metadata?.["promotional-message"] === true)
  );

  if (promotionalElement.length > 0 && promotionalElement[0].length > 0) {
    return (
      <div styleName="wrapper">
        {promotionalElement?.map((storyelement) =>
          storyelement?.map((element, idx) => (
            <div key={idx}>
              <StoryElement element={element} key={element.id} story={story} />
            </div>
          ))
        )}
      </div>
    );
  }

  return null;
}

export default PromotionalMessage;
