import React from "react";
import "./live.m.css";

function Live() {
  return (
    <div styleName="live-btn-wrapper">
      <div styleName="live-btn">
        <div styleName="oval"></div>
        <span>LIVE</span>
      </div>
    </div>
  );
}

export default Live;
