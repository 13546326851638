import React from "react";

const BlockQuoteIcon = ({ width = "40", height = "40px", fill = "#99D2DD" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 18.15v18.01H0V17.894C0 5.543 11.25 4 11.25 4l1.5 3.602s-5 .772-6 4.888c-1 3.088 1 5.66 1 5.66h9.75zm22.5 0v18.01H22.5V17.894C22.5 5.543 33.75 4 33.75 4l1.5 3.602s-5 .772-6 4.888c-1 3.088 1 5.66 1 5.66H40z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default BlockQuoteIcon;
