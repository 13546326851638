import React, { useEffect, useState, useRef } from "react";
import { ResponsiveImage } from "@quintype/components";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import { useSelector } from "react-redux";
import get from "lodash/get";
import "./image-gallery.m.css";

const ImageGallery = ({ element }) => {
  const totalLength = element["story-elements"].length;
  const itemsList = element["story-elements"].slice(0, 6);
  const remainingCount = Number(totalLength - itemsList.length);
  const allImages = element["story-elements"];
  const [ImageCounter, setImageOpen] = useState(0);
  const parentRef = useRef(null);

  useEffect(() => {
    if (window) {
      const searchParams = new URLSearchParams(window.location.search);
      const app = searchParams.get("app");
      if ((app === "true" || app === true) && ImageCounter > 0) {
        const backdrop = document.querySelector(".lg-backdrop");
        const outer = document.querySelector(".lg-outer");
        const subHtml = document.querySelector(".lg-sub-html");

        if (parentRef.current) {
          parentRef.current.style.position = "relative";
          parentRef.current.appendChild(outer);
        }

        if (backdrop) {
          backdrop.style.display = "none";
        }

        if (outer) {
          outer.style.position = "absolute";
          outer.style.height = "750px";
          outer.style.background = "black";
          // outer.style.top = -150 * Math.ceil(allImages.length / 3) + "px";
          outer.style.top = "0px";
        }

        if (subHtml) {
          subHtml.style.position = "absolute";
        }
      }
    }
  }, [ImageCounter, allImages]);

  if (!totalLength) return null;
  const publisher_name = useSelector((state) => get(state, ["qt", "config", "publisher-name"], ""));
  const CdnImage = publisher_name === "dh2" ? "https://qtstage-01.gumlet.io/" : "https://images.deccanherald.com/";

  const PhotoItem = ({ image, thumb, group, index }) => (
    <div
      styleName="qt-image-1x1"
      onClick={() => {
        setImageOpen((previousState) => previousState + 1);
      }}
    >
      <LightgalleryItem
        group={group}
        key={index}
        src={CdnImage + image["image-s3-key"]}
        thumb={CdnImage + image["image-s3-key"]}
        subHtml={`<p>
        <span class="gallery-image-title" >${image.title}</span>
        <span class="gallery-image-attribution" style="font-weight: bold; color: #dddddd; margin-left:10px;">
        ${image["image-attribution"] && image["image-attribution"]}
        </span>
        </p>`}
      >
        <ResponsiveImage
          slug={decodeURIComponent(image["image-s3-key"])}
          metadata={image["image-metadata"]}
          aspectRatio={[1, 1]}
          defaultWidth={480}
          widths={[250, 480, 640]}
          imgParams={{ auto: ["format", "compress"], fmt: "webp" }}
          alt={image["image-attribution"] || image.title || ""}
        />
        {index === 5 && remainingCount ? <div styleName="count">+{remainingCount}</div> : null}
      </LightgalleryItem>
    </div>
  );

  return (
    <div ref={parentRef}>
      <div styleName="wrapper">
        <LightgalleryProvider>
          {totalLength &&
            allImages.map((image, index) => (
              <>
                {index <= 5 && <PhotoItem key={index} image={image} group="GROUP1" index={index} />}
                {index >= 6 && (
                  <div styleName="lightbox-no-image">
                    <PhotoItem key={index} image={image} group="GROUP1" index={index} />
                  </div>
                )}
              </>
            ))}
        </LightgalleryProvider>
      </div>
    </div>
  );
};

export default ImageGallery;
