import React from "react";
import { object } from "prop-types";
import { ResponsiveImage, Link } from "@quintype/components";
import { extractTextFromHtml } from "../../helper/utils";

import "./imagewithoutshare.m.css";

function ImagewithCaptionWithoutShare({ element, story }) {
  return (
    <>
      <div styleName="wrapper">
        {element?.hyperlink ? (
          <Link href={element?.hyperlink}>
            <ResponsiveImage
              slug={decodeURIComponent(element["image-s3-key"])}
              metadata={element.metadata}
              widths={[420, 1040, 1600]}
              defaultWidth={1040}
              alt={extractTextFromHtml(element?.title) || element["image-attribution"] || ""}
            />
          </Link>
        ) : (
          <ResponsiveImage
            slug={decodeURIComponent(element["image-s3-key"])}
            metadata={element.metadata}
            widths={[420, 1040, 1600]}
            defaultWidth={1040}
            alt={extractTextFromHtml(element?.title) || element["image-attribution"] || ""}
          />
        )}
      </div>
      <div styleName="image-caption-wrapper">
        <span styleName="image-caption-text" dangerouslySetInnerHTML={{ __html: element.title }} />
        <span styleName="image-attribute" dangerouslySetInnerHTML={{ __html: element["image-attribution"] }} />
      </div>
    </>
  );
}

ImagewithCaptionWithoutShare.propTypes = {
  element: object,
  story: object
};

export default ImagewithCaptionWithoutShare;
