import React from "react";

import "./star-rating.m.css";

const StarRating = ({ rating }) => {
  const stars = ["blank-star", "blank-star", "blank-star", "blank-star", "blank-star"];
  const ratingNumber = Number(rating) && rating;

  stars.forEach((el, index) => {
    if (index < Math.floor(rating)) {
      stars.fill("full-star", 0, Math.floor(rating));
    } else if (rating % 1 >= 0.25 && rating % 1 <= 0.75) {
      stars.fill("half-star", Math.floor(rating), Math.floor(rating) + 1);
    }
  });

  return (
    <div styleName="wrapper" className="star-rating">
      <div styleName="star-wrapper">
        {stars.map((item, index) =>
          item === "full-star" ? (
            <span key={index} styleName="star">
              ★
            </span>
          ) : item === "half-star" ? (
            <span key={index} styleName="star half">
              ☆
            </span>
          ) : (
            <span key={index} styleName="star">
              ☆
            </span>
          )
        )}
      </div>
      <div styleName="count" className="star-count">
        {ratingNumber && ratingNumber}/5
      </div>
    </div>
  );
};

export default StarRating;
