import React from "react";
import "./published-at.m.css";
import { format } from "date-fns";

function PublishedAt({ story }) {
  const publishedDateAndTime = format(new Date(story["first-published-at"]), "dd MMMM yyyy, HH:mm");
  return <div styleName="date">Published {publishedDateAndTime} IST</div>;
}

export default PublishedAt;
