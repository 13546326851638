import React from "react";
import { object, number } from "prop-types";

import StoryHeader from "../story-header";
import StoryElements from "../story-elements";
import StorySideBar from "../story-sidebar";
import StorySeparator from "../../atoms/story-separator";

import "./text-story.m.css";

const TextStoryTemplate = ({ story, index }) => {
  const isMovieReview = story["story-template"] === "movie-review";
  const isGadgetReview = story["story-template"] === "gadget-review";
  const isSponsoredStory = story["story-template"] === "sponsored";
  const isBookReview = story["story-template"] === "book-review";
  const isFactCheck = story["story-template"] === "fact-check";
  const isSubhashita = story["story-template"] === "subhashita";
  const isNewTextStory = story["story-template"] === "text-new" || story["story-template"] === "news-briefs";

  return (
    <>
      {index > 0 && <StorySeparator />}
      <div styleName="story-wrapper">
        <div className="story-section">
          <div>
            <StoryHeader story={story} isSponsoredStory={isSponsoredStory} />
            <StoryElements
              story={story}
              cards={isSubhashita ? story.cards.slice(1) : story.cards}
              index={index}
              isFactCheck={isFactCheck}
              isMovieReview={isMovieReview}
              isGadgetReview={isGadgetReview}
              isSponsoredStory={isSponsoredStory}
              isBookReview={isBookReview}
              isNewTextStory={isNewTextStory}
            />
          </div>
        </div>
        <div className="side-story-section hide-mobile">
          <StorySideBar index={index} story={story} />
        </div>
      </div>
    </>
  );
};

TextStoryTemplate.propTypes = {
  story: object,
  index: number
};

const TextStory = ({ story, index }) => {
  return <TextStoryTemplate story={story} index={index} />;
};

TextStory.propTypes = {
  story: object,
  index: number
};

export default TextStory;
