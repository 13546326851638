import React from "react";
import { object } from "prop-types";
import { WithLazy } from "@quintype/components";
import CardImage from "../../../atoms/cardimage";
import { Link } from "@quintype/components";

import "./hero-image.m.css";

const HeroImage = ({ story, aspectRatio, imageType }) => {
  if (!story["hero-image-s3-key"]) {
    return null;
  }

  return (
    <WithLazy margin="20px">
      {() => (
        <div styleName="hero-image" className="hero-image">
          {story?.["hero-image-hyperlink"] ? (
            <Link href={story?.["hero-image-hyperlink"]}>
              <CardImage
                story={story}
                showMetaData={false}
                aspectRatio={aspectRatio}
                imageType={imageType}
                renderedWidth={720}
                widths={[700]}
                showAlternateImage
                showCameraIcon={false}
                showVideoIcon={true}
              />
            </Link>
          ) : (
            <CardImage
              story={story}
              showMetaData={false}
              aspectRatio={aspectRatio}
              imageType={imageType}
              renderedWidth={720}
              widths={[700]}
              showAlternateImage
              showCameraIcon={false}
              showVideoIcon={true}
            />
          )}
          {(story["hero-image-caption"] || story["hero-image-attribution"]) && (
            <div styleName="caption-container">
              {story["hero-image-caption"] && (
                <figcaption styleName="caption" dangerouslySetInnerHTML={{ __html: story["hero-image-caption"] }} />
              )}
              {story["hero-image-attribution"] && (
                <span
                  styleName="attribute"
                  dangerouslySetInnerHTML={{ __html: `${story["hero-image-attribution"]}` }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </WithLazy>
  );
};
HeroImage.defaultProps = {
  aspectRatio: [16, 9],
  imageType: "image16x9"
};

HeroImage.propTypes = {
  story: object
};

export default HeroImage;
