import React from "react";

const Sunrise = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M16.286 17.143a4.286 4.286 0 1 0-8.572 0M12 3.429v6m-6.669 1.045 1.218 1.217M2.57 17.143h1.715m15.428 0h1.715M17.45 11.69l1.218-1.217m2.76 10.097H2.57m6-13.714L12 3.43l3.429 3.428"
        stroke="#E79D0F"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
);

export default Sunrise;
