import React from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";

import "./attachment.m.css";

const Attachment = ({ element = {} }) => {
  const { "file-name": fileName, url, "content-type": contentType } = element;

  if (!contentType || !url) return null;
  const attachmentType = { PDF: "application/pdf" };
  const isPdf = contentType === attachmentType.PDF;
  const defaultName = isPdf ? "Attached PDF" : "Attached DOC";
  const defaultFileName = fileName || defaultName;

  return (
    <div styleName="wrapper" className="attached-wrapper">
      <div styleName="pdf">{isPdf ? "PDF" : "DOC"}</div>

      <div styleName="file-name" dangerouslySetInnerHTML={{ __html: defaultFileName }} />

      <Link
        styleName="button-wrapper"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        download
        aria-label="button"
      >
        {isPdf ? "View" : "View"}
      </Link>
    </div>
  );
};

Attachment.propTypes = {
  element: PropTypes.shape({
    url: PropTypes.string,
    fileName: PropTypes.string,
    contentType: PropTypes.string
  }),
  render: PropTypes.func
};

export default Attachment;
