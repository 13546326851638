import React from "react";
import { Link } from "@quintype/components";
import get from "lodash/get";
import { object } from "prop-types";

import "./reference-element.m.css";

const ReferenceElement = ({ element }) => {
  const metadata = get(element, ["metadata"], {});
  const { name = "", description = "", url = "" } = metadata;

  return (
    <div styleName="wrapper">
      <div styleName="ref-tag">Reference</div>
      <div styleName="name">{name}</div>
      <div styleName="description">{description}</div>
      <Link
        styleName="button-wrapper"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        download
        aria-label="button"
      >
        Open
      </Link>
    </div>
  );
};

ReferenceElement.propTypes = {
  element: object
};

export default ReferenceElement;
