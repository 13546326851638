import React, { useState, useEffect } from "react";
import { Link } from "@quintype/components";
import { Headline } from "@quintype/arrow";
import get from "lodash/get";
import { useSelector } from "react-redux";

import LiveWidget from "../live-widget";

import { getAStory } from "../../../helper/api";
import { getLinkedStory } from "../../../../../api/utils";

import "./also-read.m.css";
import Cardimage from "../../../atoms/cardimage";

const ConsecutiveAlsoReadCard = ({ elementIndex, linkedStory, linkedStorySlug }) => {
  return (
    <div styleName={`consecutive-also-read ${elementIndex === 0 || elementIndex === 2 ? "extra-margin" : ""}`}>
      {elementIndex !== 0 && <div className="hide-desktop" styleName="v-line"></div>}
      <Link styleName="content-wrapper" href={linkedStorySlug}>
        <div styleName="content" className="summary line-clamp-4">
          <span styleName="title">Also Read:</span>
          <span styleName="headline">{linkedStory.headline}</span>
        </div>

        <Cardimage
          story={linkedStory}
          aspectRatio={[16, 9]}
          imageType="image16x9"
          renderedWidth={720}
          widths={[700]}
          showVideoIcon={true}
        />
      </Link>
    </div>
  );
};
const AlsoReadCard = (props) => {
  const { story, element, isConsecutive, elementIndex } = props;

  const { linkedStory, linkedStorySlug } = getLinkedStory(element, story);

  if (isConsecutive) {
    return (
      <div styleName="consecutive-main-wrapper">
        {(elementIndex === 1 || elementIndex === 3) && <div className="hide-mobile" styleName="vertical-line"></div>}
        <ConsecutiveAlsoReadCard
          elementIndex={elementIndex}
          linkedStory={linkedStory}
          linkedStorySlug={linkedStorySlug}
        />
      </div>
    );
  }
  return (
    <>
      <div styleName="single-also-read" className="hide-mobile">
        <Link styleName="content-wrapper" href={`/${linkedStorySlug}`}>
          <div styleName="content">
            <span styleName="title">Also Read:</span>
            <Headline story={linkedStory} headerLevel="4" />
          </div>
        </Link>
      </div>
      <div className="hide-desktop">
        <ConsecutiveAlsoReadCard
          elementIndex={elementIndex}
          linkedStory={linkedStory}
          linkedStorySlug={linkedStorySlug}
        />
      </div>
    </>
  );
};

const AlsoRead = (props) => {
  const { story, element } = props;
  const storyReducer = useSelector((state) => get(state, ["storyReducer"], []));
  const makeSecondaryApiCalls = storyReducer.makeSecondaryApiCalls;
  const delay = storyReducer.delay;

  const { linkedStory, linkedStorySlug } = getLinkedStory(element, story);
  const [alsoReadStory, setAlsoReadStory] = useState({});
  const [isVisible, setIsVisible] = useState(props?.isImmersiveStory ? true : false);
  const showLiveWidget = get(story, ["cards", 0, "metadata", "attributes", "liveblogwidget"], false);

  useEffect(() => {
    if (makeSecondaryApiCalls) {
      setTimeout(() => getAStory(linkedStory["story-content-id"]).then((res) => setAlsoReadStory(res)), delay);
      if (!props?.isImmersiveStory) {
        setTimeout(() => setIsVisible(true), delay + 500);
      }
    }
  }, [makeSecondaryApiCalls]);

  if (isVisible) {
    if (showLiveWidget[0] === "Yes" && alsoReadStory["story-template"] === "live-blog") {
      return <LiveWidget story={alsoReadStory} />;
    } else {
      return (
        <div styleName="also-read-wrapper">
          <AlsoReadCard {...props} />
        </div>
      );
    }
  } else {
    return null;
  }
};

export default AlsoRead;
