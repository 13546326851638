import React from "react";
import get from "lodash/get";
import { object } from "prop-types";

import StarRating from "../../../atoms/star-rating";

import "./book-review.m.css";

const BookReviewView = ({ story }) => {
  const MetaData = get(story, ["metadata"]);
  const booktTitle = get(MetaData, ["book-title"], "");
  const author = get(MetaData, ["book-author"], "");
  const publisher = get(MetaData, ["book-publisher"], "");
  const rating = get(MetaData, ["book-rating"], "");

  if (booktTitle === "" && author === "" && publisher === "" && rating === "") {
    return null;
  }

  return (
    <div styleName="wrapper">
      <div styleName="text-and-rating">
        {booktTitle.length > 0 && (
          <div styleName="title-wrapper">
            <div styleName="title">{booktTitle}</div>
          </div>
        )}
        {rating.length > 0 && <StarRating rating={rating} />}
      </div>
      {author.length > 0 && (
        <div styleName="details">
          <span styleName="entity-name">Author:</span>
          <span styleName="entity-value">{author}</span>
        </div>
      )}
      {publisher.length > 0 && (
        <div styleName="details">
          <span styleName="entity-name">Publisher:</span>
          <span styleName="entity-value">{publisher}</span>
        </div>
      )}
    </div>
  );
};

BookReviewView.propTypes = {
  story: object
};

export default BookReviewView;
