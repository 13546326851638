import React from "react";

const QuoteIcon = ({ width = "20px", height = "34px", fill = "#0091AC" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 33" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 14.15V32.16H0V13.894C0 1.543 11.25 0 11.25 0L12.75 3.602C12.75 3.602 7.75 4.374 6.75 8.49C5.75 11.578 7.75 14.15 7.75 14.15H17.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default QuoteIcon;
