import React from "react";
import PT from "prop-types";

export const AppStore = ({ height = "155", width = "46" }) => (
  <svg width={width} height={height} viewBox="0 0 153 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M151.073 38.9542C151.073 41.0146 149.366 42.7015 147.232 42.7015H5.19282C3.05904 42.7015 1.3468 41.0137 1.3468 38.9542V5.08363C1.3468 3.02436 3.05998 1.33081 5.19282 1.33081H147.231C149.365 1.33081 151.072 3.02335 151.072 5.08363L151.073 38.9542Z"
      stroke="#DDDDDD"
    />
    <path
      d="M34.0155 21.7784C33.9828 18.2305 36.9951 16.5044 37.1328 16.4241C35.4268 13.999 32.7826 13.6677 31.8534 13.6412C29.6326 13.4134 27.4784 14.9369 26.3471 14.9369C25.1933 14.9369 23.4512 13.6633 21.5736 13.7007C19.1575 13.737 16.8971 15.1009 15.6575 17.2189C13.0991 21.5373 15.0071 27.8835 17.4583 31.3741C18.6844 33.0837 20.1171 34.9925 21.9925 34.9253C23.8271 34.8516 24.5125 33.7849 26.7265 33.7849C28.9202 33.7849 29.5638 34.9253 31.4763 34.8824C33.4454 34.8516 34.6851 33.1652 35.8683 31.4402C37.2852 29.4808 37.8542 27.551 37.8768 27.452C37.8305 27.4366 34.0528 26.0308 34.0155 21.7784Z"
      fill="#444444"
    />
    <path
      d="M30.4026 11.345C31.3894 10.1418 32.0646 8.50487 31.8771 6.84375C30.4489 6.9054 28.6628 7.80696 27.6342 8.98372C26.7242 10.0207 25.9113 11.7203 26.1213 13.3187C27.7257 13.4354 29.373 12.5294 30.4026 11.345Z"
      fill="#444444"
    />
    <path
      d="M55.3791 11.018C55.3791 12.3136 54.9805 13.289 54.1846 13.9439C53.4473 14.5483 52.3996 14.851 51.0425 14.851C50.3696 14.851 49.7937 14.8224 49.3116 14.7651V7.68584C49.9405 7.58677 50.6179 7.53613 51.3496 7.53613C52.6423 7.53613 53.6167 7.81023 54.2738 8.35844C55.0099 8.97819 55.3791 9.86434 55.3791 11.018ZM54.1315 11.0499C54.1315 10.21 53.9034 9.56602 53.4473 9.11689C52.9912 8.66886 52.325 8.4443 51.4478 8.4443C51.0752 8.4443 50.7579 8.46852 50.4949 8.51915V13.901C50.6405 13.923 50.907 13.9329 51.2942 13.9329C52.1997 13.9329 52.8986 13.6874 53.3909 13.1965C53.8831 12.7055 54.1315 11.99 54.1315 11.0499Z"
      fill="#444444"
    />
    <path
      d="M61.9941 12.1497C61.9941 12.9477 61.7604 13.6016 61.293 14.1146C60.803 14.6419 60.1538 14.905 59.3432 14.905C58.5619 14.905 57.9398 14.6529 57.4757 14.1465C57.0128 13.6413 56.7814 13.0039 56.7814 12.2355C56.7814 11.4319 57.0196 10.7725 57.4983 10.2607C57.977 9.7488 58.6206 9.49231 59.4312 9.49231C60.2125 9.49231 60.8402 9.74439 61.3156 10.2497C61.7672 10.7406 61.9941 11.3747 61.9941 12.1497ZM60.7669 12.1871C60.7669 11.7082 60.6607 11.2976 60.4496 10.9553C60.2012 10.5414 59.8478 10.3344 59.3883 10.3344C58.913 10.3344 58.5517 10.5414 58.3033 10.9553C58.0911 11.2976 57.9861 11.7148 57.9861 12.208C57.9861 12.6869 58.0922 13.0975 58.3033 13.4398C58.5596 13.8537 58.9164 14.0607 59.377 14.0607C59.8286 14.0607 60.1831 13.8504 60.4383 13.4288C60.6573 13.0798 60.7669 12.6659 60.7669 12.1871Z"
      fill="#444444"
    />
    <path
      d="M70.8638 9.59796L69.1984 14.7872H68.1146L67.4247 12.5338C67.2497 11.9713 67.1075 11.4121 66.9968 10.8573H66.9754C66.8726 11.4275 66.7304 11.9856 66.5475 12.5338L65.8147 14.7872H64.7184L63.1525 9.59796H64.3684L64.9702 12.0649C65.1159 12.6483 65.2355 13.2042 65.3315 13.7304H65.3529C65.441 13.2967 65.5867 12.7441 65.7921 12.0759L66.5475 9.59906H67.5117L68.2354 12.023C68.4104 12.6142 68.5526 13.1833 68.6621 13.7315H68.6949C68.775 13.1976 68.8959 12.6285 69.0562 12.023L69.702 9.59906H70.8638V9.59796Z"
      fill="#444444"
    />
    <path
      d="M76.9978 14.7872H75.8146V11.815C75.8146 10.8991 75.4578 10.4412 74.742 10.4412C74.3909 10.4412 74.1075 10.5667 73.8873 10.8188C73.6694 11.0709 73.5588 11.3681 73.5588 11.7082V14.7861H72.3755V11.0808C72.3755 10.625 72.3609 10.1308 72.3326 9.59579H73.3725L73.4278 10.4071H73.4605C73.5983 10.155 73.8038 9.94695 74.0736 9.78073C74.3943 9.58699 74.7533 9.48901 75.1462 9.48901C75.643 9.48901 76.0562 9.64533 76.3847 9.95906C76.7934 10.3432 76.9978 10.9168 76.9978 11.6785V14.7872Z"
      fill="#444444"
    />
    <path d="M80.2607 14.7872H79.0786V7.21692H80.2607V14.7872Z" fill="#444444" />
    <path
      d="M87.2269 12.1497C87.2269 12.9477 86.9932 13.6016 86.5258 14.1146C86.0358 14.6419 85.3855 14.905 84.5759 14.905C83.7935 14.905 83.1714 14.6529 82.7085 14.1465C82.2456 13.6413 82.0142 13.0039 82.0142 12.2355C82.0142 11.4319 82.2524 10.7725 82.7311 10.2607C83.2098 9.7488 83.8534 9.49231 84.6629 9.49231C85.4453 9.49231 86.0719 9.74439 86.5484 10.2497C87 10.7406 87.2269 11.3747 87.2269 12.1497ZM85.9985 12.1871C85.9985 11.7082 85.8924 11.2976 85.6813 10.9553C85.434 10.5414 85.0795 10.3344 84.6211 10.3344C84.1446 10.3344 83.7834 10.5414 83.5361 10.9553C83.3238 11.2976 83.2188 11.7148 83.2188 12.208C83.2188 12.6869 83.325 13.0975 83.5361 13.4398C83.7924 13.8537 84.1492 14.0607 84.6098 14.0607C85.0614 14.0607 85.4148 13.8504 85.67 13.4288C85.8901 13.0798 85.9985 12.6659 85.9985 12.1871Z"
      fill="#444444"
    />
    <path
      d="M92.9533 14.7872H91.8909L91.8029 14.1895H91.7701C91.4066 14.6661 90.8883 14.905 90.2154 14.905C89.713 14.905 89.3066 14.7476 89.0006 14.4349C88.7229 14.1509 88.584 13.7976 88.584 13.3782C88.584 12.7441 88.855 12.2608 89.4003 11.9262C89.9445 11.5915 90.71 11.4275 91.6956 11.4352V11.3384C91.6956 10.6548 91.3275 10.3135 90.5903 10.3135C90.0653 10.3135 89.6024 10.4423 89.2027 10.6977L88.9622 9.94034C89.4567 9.64202 90.0675 9.49231 90.7879 9.49231C92.1788 9.49231 92.8766 10.2078 92.8766 11.6389V13.5499C92.8766 14.0684 92.9025 14.4812 92.9533 14.7872ZM91.725 13.0039V12.2036C90.4198 12.1816 89.7672 12.5305 89.7672 13.2494C89.7672 13.5202 89.8417 13.7227 89.9941 13.8581C90.1466 13.9935 90.3408 14.0607 90.5722 14.0607C90.8319 14.0607 91.0746 13.9803 91.2959 13.8207C91.5183 13.66 91.655 13.4563 91.7058 13.2064C91.7182 13.1503 91.725 13.082 91.725 13.0039Z"
      fill="#444444"
    />
    <path
      d="M99.6767 14.7872H98.6267L98.5714 13.9539H98.5386C98.2033 14.5879 97.632 14.905 96.8293 14.905C96.188 14.905 95.654 14.6595 95.2306 14.1685C94.8072 13.6776 94.5961 13.0402 94.5961 12.2575C94.5961 11.4176 94.8253 10.7373 95.2859 10.2177C95.7319 9.73336 96.2783 9.49119 96.9286 9.49119C97.6433 9.49119 98.1435 9.72566 98.428 10.1957H98.4506V7.21692H99.6349V13.3891C99.6349 13.8944 99.6485 14.3601 99.6767 14.7872ZM98.4506 12.5988V11.7335C98.4506 11.5838 98.4393 11.4627 98.4178 11.3703C98.3512 11.0929 98.2078 10.8595 97.9899 10.6713C97.7698 10.483 97.5044 10.3883 97.1985 10.3883C96.757 10.3883 96.4115 10.559 96.1575 10.9013C95.9057 11.2437 95.7782 11.6807 95.7782 12.2146C95.7782 12.7276 95.899 13.1437 96.1417 13.464C96.398 13.8053 96.7435 13.9759 97.1759 13.9759C97.5643 13.9759 97.8748 13.8339 98.1107 13.5488C98.3388 13.2857 98.4506 12.9686 98.4506 12.5988Z"
      fill="#444444"
    />
    <path
      d="M109.796 12.1497C109.796 12.9477 109.563 13.6016 109.095 14.1146C108.605 14.6419 107.957 14.905 107.145 14.905C106.365 14.905 105.743 14.6529 105.278 14.1465C104.815 13.6413 104.583 13.0039 104.583 12.2355C104.583 11.4319 104.822 10.7725 105.3 10.2607C105.779 9.7488 106.423 9.49231 107.234 9.49231C108.015 9.49231 108.644 9.74439 109.118 10.2497C109.569 10.7406 109.796 11.3747 109.796 12.1497ZM108.57 12.1871C108.57 11.7082 108.464 11.2976 108.253 10.9553C108.003 10.5414 107.651 10.3344 107.19 10.3344C106.716 10.3344 106.355 10.5414 106.105 10.9553C105.893 11.2976 105.788 11.7148 105.788 12.208C105.788 12.6869 105.894 13.0975 106.105 13.4398C106.362 13.8537 106.718 14.0607 107.179 14.0607C107.631 14.0607 107.986 13.8504 108.242 13.4288C108.459 13.0798 108.57 12.6659 108.57 12.1871Z"
      fill="#444444"
    />
    <path
      d="M116.158 14.7872H114.976V11.815C114.976 10.8991 114.619 10.4412 113.902 10.4412C113.551 10.4412 113.268 10.5667 113.049 10.8188C112.83 11.0709 112.72 11.3681 112.72 11.7082V14.7861H111.536V11.0808C111.536 10.625 111.522 10.1308 111.494 9.59579H112.533L112.588 10.4071H112.621C112.76 10.155 112.965 9.94695 113.234 9.78073C113.556 9.58699 113.914 9.48901 114.308 9.48901C114.803 9.48901 115.217 9.64533 115.545 9.95906C115.955 10.3432 116.158 10.9168 116.158 11.6785V14.7872Z"
      fill="#444444"
    />
    <path
      d="M124.121 10.4621H122.819V12.9829C122.819 13.6236 123.05 13.9439 123.508 13.9439C123.721 13.9439 123.897 13.9263 124.036 13.89L124.066 14.7651C123.832 14.851 123.525 14.8939 123.147 14.8939C122.68 14.8939 122.316 14.7552 122.053 14.4778C121.789 14.2004 121.658 13.7337 121.658 13.0787V10.4621H120.88V9.59795H121.658V8.64685L122.817 8.3056V9.59685H124.12V10.4621H124.121Z"
      fill="#444444"
    />
    <path
      d="M130.385 14.7872H129.201V11.837C129.201 10.9068 128.844 10.4412 128.13 10.4412C127.581 10.4412 127.206 10.7109 127 11.2503C126.965 11.3637 126.945 11.5024 126.945 11.6653V14.7861H125.763V7.21692H126.945V10.3443H126.968C127.34 9.7752 127.874 9.49119 128.566 9.49119C129.056 9.49119 129.462 9.6475 129.784 9.96123C130.184 10.352 130.385 10.9332 130.385 11.7016V14.7872Z"
      fill="#444444"
    />
    <path
      d="M136.847 11.9471C136.847 12.154 136.831 12.3279 136.803 12.47H133.254C133.27 12.9829 133.439 13.3737 133.768 13.6445C134.068 13.8867 134.455 14.0078 134.93 14.0078C135.455 14.0078 135.933 13.9263 136.365 13.7623L136.55 14.5637C136.045 14.7772 135.451 14.884 134.764 14.884C133.94 14.884 133.29 14.6473 132.821 14.174C132.349 13.7007 132.115 13.0655 132.115 12.2685C132.115 11.4858 132.333 10.8342 132.772 10.3146C133.23 9.75976 133.85 9.48236 134.633 9.48236C135.398 9.48236 135.98 9.75976 136.373 10.3146C136.69 10.7549 136.847 11.2998 136.847 11.9471ZM135.718 11.6487C135.727 11.3064 135.649 11.0114 135.489 10.7626C135.283 10.4423 134.97 10.2815 134.547 10.2815C134.161 10.2815 133.846 10.4379 133.605 10.7516C133.409 11.0015 133.293 11.2998 133.254 11.6487H135.718Z"
      fill="#444444"
    />
    <path
      d="M60.567 34.6799H58.003L56.5985 30.3768H51.7165L50.3786 34.6799H47.8823L52.7191 20.0303H55.7065L60.567 34.6799ZM56.1751 28.5715L54.9049 24.7462C54.7706 24.3554 54.5188 23.4351 54.1473 21.9865H54.1022C53.9543 22.6095 53.716 23.5298 53.3886 24.7462L52.141 28.5715H56.1751Z"
      fill="#444444"
    />
    <path
      d="M73.0055 29.2683C73.0055 31.0648 72.5076 32.4849 71.5118 33.5273C70.6199 34.4553 69.5123 34.9187 68.1902 34.9187C66.7631 34.9187 65.738 34.419 65.1136 33.4194H65.0684V38.984H62.6613V27.594C62.6613 26.4645 62.6309 25.3054 62.5721 24.1165H64.6891L64.8234 25.7908H64.8686C65.6713 24.5293 66.8896 23.8997 68.5244 23.8997C69.8025 23.8997 70.8694 24.3917 71.723 25.3769C72.5788 26.3633 73.0055 27.66 73.0055 29.2683ZM70.5533 29.3542C70.5533 28.326 70.3162 27.4784 69.8397 26.8113C69.3192 26.1156 68.6204 25.7677 67.7442 25.7677C67.1504 25.7677 66.6107 25.9615 66.1286 26.3434C65.6454 26.7287 65.3292 27.2318 65.1813 27.8549C65.1068 28.1455 65.0696 28.3832 65.0696 28.5704V30.3317C65.0696 31.1 65.3112 31.7484 65.7944 32.2779C66.2776 32.8074 66.9054 33.0716 67.6776 33.0716C68.5842 33.0716 69.2899 32.7303 69.7946 32.05C70.3004 31.3686 70.5533 30.4704 70.5533 29.3542Z"
      fill="#444444"
    />
    <path
      d="M85.4667 29.2683C85.4667 31.0648 84.9688 32.4849 83.9719 33.5273C83.081 34.4553 81.9735 34.9187 80.6514 34.9187C79.2243 34.9187 78.1991 34.419 77.5759 33.4194H77.5307V38.984H75.1236V27.594C75.1236 26.4645 75.0931 25.3054 75.0344 24.1165H77.1514L77.2857 25.7908H77.3309C78.1325 24.5293 79.3507 23.8997 80.9867 23.8997C82.2636 23.8997 83.3306 24.3917 84.1864 25.3769C85.0388 26.3633 85.4667 27.66 85.4667 29.2683ZM83.0144 29.3542C83.0144 28.326 82.7762 27.4784 82.2998 26.8113C81.7793 26.1156 81.0827 25.7677 80.2054 25.7677C79.6104 25.7677 79.0719 25.9615 78.5886 26.3434C78.1054 26.7287 77.7904 27.2318 77.6425 27.8549C77.5691 28.1455 77.5307 28.3832 77.5307 28.5704V30.3317C77.5307 31.1 77.7723 31.7484 78.2533 32.2779C78.7365 32.8063 79.3643 33.0716 80.1388 33.0716C81.0454 33.0716 81.751 32.7303 82.2557 32.05C82.7615 31.3686 83.0144 30.4704 83.0144 29.3542Z"
      fill="#444444"
    />
    <path
      d="M99.3989 30.5717C99.3989 31.8178 98.9552 32.8316 98.0644 33.6143C97.0855 34.4696 95.7228 34.8967 93.9717 34.8967C92.3549 34.8967 91.0588 34.5929 90.0776 33.9842L90.6354 32.028C91.6922 32.6511 92.8517 32.9637 94.1151 32.9637C95.0217 32.9637 95.7273 32.7634 96.2343 32.3649C96.7389 31.9664 96.9907 31.4314 96.9907 30.7643C96.9907 30.1699 96.783 29.669 96.3663 29.2628C95.952 28.8566 95.2599 28.479 94.2934 28.1301C91.6628 27.1735 90.3486 25.7721 90.3486 23.9294C90.3486 22.7251 90.8093 21.7377 91.7317 20.9693C92.6507 20.1999 93.8768 19.8157 95.4101 19.8157C96.7773 19.8157 97.9131 20.0479 98.8197 20.5114L98.218 22.4246C97.3712 21.9755 96.4138 21.7509 95.3423 21.7509C94.4955 21.7509 93.8339 21.9545 93.3597 22.3596C92.9589 22.7218 92.758 23.1632 92.758 23.6861C92.758 24.2651 92.9872 24.744 93.4478 25.1205C93.8486 25.4683 94.5768 25.8448 95.6336 26.251C96.9264 26.7585 97.8759 27.3518 98.4867 28.0321C99.0952 28.7102 99.3989 29.5589 99.3989 30.5717Z"
      fill="#444444"
    />
    <path
      d="M107.358 25.8778H104.704V31.0065C104.704 32.3109 105.172 32.9626 106.109 32.9626C106.539 32.9626 106.896 32.9263 107.178 32.8536L107.245 34.6358C106.77 34.8087 106.146 34.8956 105.373 34.8956C104.422 34.8956 103.679 34.6127 103.143 34.048C102.609 33.4822 102.34 32.5333 102.34 31.2002V25.8756H100.76V24.1143H102.34V22.1802L104.704 21.4845V24.1143H107.358V25.8778Z"
      fill="#444444"
    />
    <path
      d="M119.329 29.3112C119.329 30.9349 118.852 32.268 117.902 33.3105C116.905 34.3837 115.581 34.9187 113.932 34.9187C112.342 34.9187 111.077 34.4047 110.133 33.3765C109.189 32.3484 108.717 31.0505 108.717 29.4863C108.717 27.8494 109.202 26.5086 110.177 25.4661C111.149 24.4225 112.461 23.9008 114.11 23.9008C115.7 23.9008 116.979 24.4148 117.944 25.4441C118.868 26.4425 119.329 27.7316 119.329 29.3112ZM116.831 29.3872C116.831 28.413 116.618 27.5775 116.185 26.8806C115.681 26.0374 114.959 25.6169 114.024 25.6169C113.057 25.6169 112.322 26.0385 111.817 26.8806C111.385 27.5786 111.171 28.4273 111.171 29.4312C111.171 30.4054 111.385 31.2409 111.817 31.9367C112.338 32.7799 113.065 33.2004 114.003 33.2004C114.922 33.2004 115.644 32.7711 116.164 31.9146C116.608 31.2046 116.831 30.3603 116.831 29.3872Z"
      fill="#444444"
    />
    <path
      d="M127.153 26.1805C126.915 26.1376 126.661 26.1156 126.394 26.1156C125.547 26.1156 124.893 26.4271 124.432 27.0513C124.031 27.6017 123.83 28.2974 123.83 29.1373V34.6799H121.424L121.447 27.4432C121.447 26.2257 121.416 25.1172 121.356 24.1176H123.453L123.541 26.1387H123.608C123.862 25.4441 124.263 24.8849 124.811 24.4655C125.348 24.0879 125.927 23.8997 126.551 23.8997C126.774 23.8997 126.975 23.9151 127.153 23.9426V26.1805Z"
      fill="#444444"
    />
    <path
      d="M137.918 28.8984C137.918 29.3189 137.89 29.6734 137.83 29.9629H130.609C130.637 31.0064 130.986 31.8045 131.657 32.3549C132.265 32.847 133.052 33.0936 134.019 33.0936C135.088 33.0936 136.063 32.9274 136.94 32.5938L137.318 34.223C136.292 34.6589 135.082 34.8758 133.685 34.8758C132.005 34.8758 130.687 34.3936 129.727 33.4304C128.77 32.4672 128.29 31.1738 128.29 29.5512C128.29 27.9583 128.736 26.6318 129.629 25.574C130.564 24.4445 131.827 23.8798 133.417 23.8798C134.978 23.8798 136.16 24.4445 136.963 25.574C137.599 26.4711 137.918 27.5807 137.918 28.8984ZM135.623 28.2897C135.639 27.5939 135.482 26.9929 135.155 26.4854C134.739 25.8327 134.099 25.5068 133.237 25.5068C132.45 25.5068 131.81 25.8249 131.321 26.4634C130.92 26.9709 130.682 27.5796 130.609 28.2886H135.623V28.2897Z"
      fill="#444444"
    />
  </svg>
);

AppStore.propTypes = {
  onMenuToggle: PT.func,
  isMegaMenuOpen: PT.bool
};

export default AppStore;
