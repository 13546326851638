import React from "react";
import get from "lodash/get";

import StarRating from "../../../atoms/star-rating";

import "./gadget-review.m.css";

const GadgetReviewView = ({ story }) => {
  const metaData = get(story, ["metadata"]);
  const gadgetName = get(metaData, ["device-name"], "");
  const pro = get(metaData, ["pros"], "");
  const cons = get(metaData, ["cons"], "");
  const specs = get(metaData, ["specs"], "");
  const rating = get(metaData, ["gadget-rating"], "");

  if (gadgetName === "" && pro === "" && cons === "" && specs === "" && rating === "") {
    return null;
  }

  return (
    <div styleName="wrapper">
      <div styleName="text-and-rating">
        {gadgetName.length > 0 && (
          <div styleName="title-wrapper">
            <div styleName="title">{gadgetName}</div>
          </div>
        )}
        {rating.length > 0 && <StarRating rating={rating} />}
      </div>

      <ul styleName="details">
        {pro.length > 0 && (
          <li>
            <div styleName="disc">Pros:</div>
            <p>{pro}</p>
          </li>
        )}
        {cons.length > 0 && (
          <li>
            <div styleName="disc">Cons:</div>
            <p>{cons}</p>
          </li>
        )}
      </ul>
      {specs.length > 0 && (
        <div styleName="specs">
          <div styleName="disc">Specifications:</div>
          <p>{specs}</p>
        </div>
      )}
    </div>
  );
};

export default GadgetReviewView;
