import React from "react";

const FollowUsGoogleIcon = ({ isSubscribeCard = false }) => (
  <div className={`follow-us-google-wrapper ${isSubscribeCard ? "subscribe-google" : ""}`}>
    <svg width="20.74px" height="20px" viewBox="0 0 49.091 40" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient
          cx="22.545%"
          cy="8.1%"
          fx="22.545%"
          fy="8.1%"
          r="119.339%"
          gradientTransform="matrix(.8148 0 0 1 .042 0)"
          id="u6r5ke5jda"
        >
          <stop stopColor="#FFF" stopOpacity=".1" offset="0%" />
          <stop stopColor="#FFF" stopOpacity="0" offset="100%" />
        </radialGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M38.788 27.243a1.253 1.253 0 0 1-1.256 1.242H10.957a1.253 1.253 0 0 1-1.26-1.242v-26A1.259 1.259 0 0 1 10.957 0h26.575c.69 0 1.252.555 1.256 1.243v26z"
          fill="#0C9D58"
        />
        <path
          fill="#004D40"
          opacity=".2"
          d="M38.788 6.618 29.09 5.455l9.697 2.424zM25.105 1.309l-14.802 6.57L26.667 1.96v-.016c-.286-.613-.989-.898-1.558-.635l-.004-.001z"
        />
        <path
          d="M37.532 0H10.957c-.69 0-1.253.662-1.26 1.486v.332c.007-.823.57-1.485 1.26-1.485h26.575c.69-.001 1.252.663 1.256 1.485v-.332C38.784.663 38.223-.001 37.532 0z"
          fill="#FFF"
          opacity=".2"
        />
        <path
          d="M16.41 26.495c-.18.66.179 1.336.796 1.495l24.596 6.52c.617.163 1.27-.244 1.449-.903l5.794-21.313c.179-.66-.18-1.337-.797-1.495l-24.596-6.52c-.617-.163-1.27.243-1.448.902L16.41 26.495z"
          fill="#EA4335"
        />
        <path fill="#3E2723" opacity=".2" d="m43.636 19.394 1.212 7.883-1.212 4.844zM29.09 6.06l1.123.294.696 4.555z" />
        <path
          d="M48.244 10.817 23.63 4.278c-.618-.162-1.27.244-1.449.906l-5.8 21.374c-.01.037-.012.072-.018.109l5.755-21.214c.18-.66.832-1.068 1.45-.905l24.617 6.543c.581.154.93.766.815 1.392l.044-.161c.18-.662-.18-1.34-.797-1.5l-.004-.005z"
          fill="#FFF"
          opacity=".2"
        />
        <path
          d="M35.677 23.535c.235.646-.06 1.352-.657 1.569L9.21 34.482c-.598.216-1.279-.135-1.514-.781L.081 12.825c-.235-.646.06-1.352.657-1.568l25.811-9.375c.597-.216 1.277.135 1.513.78l7.615 20.873z"
          fill="#FFC107"
        />
        <path
          d="M.178 13.166c-.233-.65.06-1.361.65-1.58l25.56-9.439c.585-.216 1.25.129 1.49.766l-.087-.245c-.234-.65-.908-1.004-1.499-.785L.732 11.322C.14 11.54-.154 12.25.08 12.9l7.542 21.02a.195.195 0 0 0 .01.02L.178 13.165z"
          fill="#FFF"
          opacity=".2"
        />
        <path
          d="M43.025 38.752c0 .69-.558 1.248-1.245 1.248H6.7a1.247 1.247 0 0 1-1.245-1.248V12.157c0-.689.557-1.248 1.244-1.248h35.086c.688 0 1.245.559 1.245 1.248l-.005 26.595z"
          fill="#4285F4"
        />
        <path
          d="M36.574 21.566H26.061v-2.778h10.513c.306 0 .554.249.554.555v1.666a.557.557 0 0 1-.554.557zm0 10.555H26.061v-2.778h10.513c.306 0 .554.249.554.556v1.668a.554.554 0 0 1-.554.554zm1.66-5.278H26.062v-2.778h12.174c.305 0 .552.249.553.555v1.667a.554.554 0 0 1-.553.556z"
          fill="#FFF"
        />
        <path
          d="M41.785 39.67H6.7c-.687 0-1.244-.667-1.244-1.488v.33c0 .822.557 1.488 1.244 1.488h35.086c.688 0 1.245-.666 1.245-1.487v-.331c0 .821-.557 1.487-1.245 1.487z"
          fill="#1A237E"
          opacity=".2"
        />
        <path
          d="M6.7 11.24h35.085c.688 0 1.245.666 1.245 1.487v-.33c0-.822-.557-1.488-1.245-1.488H6.7c-.687 0-1.244.666-1.244 1.488v.33c0-.821.557-1.487 1.244-1.487z"
          fill="#FFF"
          opacity=".2"
        />
        <path
          d="M16.55 24.344v2.503h3.65c-.302 1.526-1.663 2.633-3.65 2.633-2.216 0-4.01-1.846-4.01-4.028 0-2.182 1.798-4.027 4.01-4.027a3.709 3.709 0 0 1 2.597.999l1.932-1.903a6.56 6.56 0 0 0-4.53-1.733c-2.438-.028-4.704 1.236-5.931 3.31a6.579 6.579 0 0 0 0 6.713c1.227 2.074 3.493 3.339 5.93 3.31 3.912 0 6.482-2.707 6.482-6.515a7.883 7.883 0 0 0-.106-1.262H16.55z"
          fill="#FFF"
        />
        <g opacity=".2" fill="#1A237E">
          <path d="M16.505 26.448v.284h3.604c.027-.093.05-.188.068-.284h-3.672z" />
          <path d="M16.505 31.838a6.811 6.811 0 0 1-6.808-6.673v.142a6.813 6.813 0 0 0 6.812 6.814c3.935 0 6.521-2.766 6.521-6.659v-.091c-.092 3.79-2.656 6.467-6.525 6.467zm2.613-9.91a3.703 3.703 0 0 0-2.613-1.022c-2.23 0-4.034 1.887-4.034 4.117 0 .049 0 .099.007.142.076-2.165 1.849-3.975 4.034-3.975a3.693 3.693 0 0 1 2.606 1.026l2.085-2.086a2.953 2.953 0 0 0-.153-.13l-1.931 1.933-.001-.005z" />
        </g>
        <path
          d="M37.128 20.887v-.28c0 .31-.248.56-.554.56H26.061v.28h10.513a.547.547 0 0 0 .392-.163.564.564 0 0 0 .162-.398v.001zm-.554 10.954H26.061v.28h10.513a.558.558 0 0 0 .554-.562v-.279c0 .31-.248.56-.554.56zm1.66-5.337H26.062v.281h12.174a.558.558 0 0 0 .553-.562v-.28c0 .31-.248.56-.553.56z"
          fill="#1A237E"
          opacity=".2"
        />
        <path
          d="m48.246 10.508-9.195-2.464V1.238A1.248 1.248 0 0 0 37.799 0H11.297c-.689 0-1.25.552-1.257 1.238v6.78l-9.3 3.37c-.598.216-.895.923-.658 1.57l5.501 15.041v10.75c0 .69.563 1.251 1.257 1.251h35.418c.694 0 1.257-.56 1.257-1.252v-6.17l5.53-20.562c.179-.665-.179-1.348-.795-1.508h-.004z"
          fill="url(#u6r5ke5jda)"
        />
      </g>
    </svg>
  </div>
);

export default FollowUsGoogleIcon;
