import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import { object, bool } from "prop-types";
import Resizer from "../resizer";
import FollowUs from "../follow-us";

import Authors from "../../../atoms/author";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import SocialMediaShare from "../../../molecules/social-media-share";

import "./author-and-timestamp.m.css";
import CommentsCount from "../../../atoms/comments-count";

const AuthorAndTimeStamp = ({ story, isPVExclusive, isSponsoredStory }) => {
  const [showSharingOptions, setShowSharingOptions] = useState(false);
  const lastUpdatedDateAndTime = format(new Date(story && story["last-published-at"]), "dd MMMM yyyy, HH:mm");
  const shareRef = useRef(null);
  const [timestampShareWidth, setTimestampShareWidth] = useState("auto");
  const timestampShareRef = useRef(null);

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const authorWrapper = document.querySelector("#author-wrapper-id");
    const desktopHeader = document.querySelector("#desk-header");
    const authorId = document.querySelector("#author-resizer-wrapper");
    const primaryMenu = document.querySelector(".primary-menu");
    const stickyShare = document.querySelector(".sticky-div");
    const stickyShareTop = stickyShare?.getBoundingClientRect().top;

    let previousScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const authorWrapperBottom = authorWrapper?.getBoundingClientRect().bottom;
      const desktopHeaderBottom = desktopHeader?.getBoundingClientRect().bottom;
      const authorTop = authorId?.getBoundingClientRect().top;
      const primaryMenuBottom = primaryMenu?.getBoundingClientRect().bottom;

      if (typeof window !== "undefined") {
        if (isMobile) {
          if (currentScrollY >= stickyShareTop) {
            stickyShare.style.top = primaryMenuBottom - 12 + "px";
            setIsSticky(true);
          } else {
            if (currentScrollY < previousScrollY && currentScrollY <= stickyShareTop - 35) {
              setIsSticky(false);
            }
          }
        } else {
          if (isSponsoredStory) {
            desktopHeaderBottom >= authorTop + 10 ? setIsSticky(true) : setIsSticky(false);
          } else {
            desktopHeaderBottom >= authorWrapperBottom + 10 ? setIsSticky(true) : setIsSticky(false);
          }
        }
      }
      previousScrollY = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMobile, setIsSticky]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSharingOptions && !shareRef.current.contains(event.target)) {
        setShowSharingOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showSharingOptions]);

  useEffect(() => {
    if (timestampShareRef.current) {
      let width = timestampShareRef.current.offsetWidth;
      if (isMobile) {
        width += 3;
      }
      setTimestampShareWidth(`${width}px`);
    }
  }, [isMobile]);

  const sharingHandler = () => {
    setShowSharingOptions(!showSharingOptions);
  };

  if (isPVExclusive && !isMobile) {
    return (
      <div styleName="pvexclusive">
        {!isSponsoredStory && (
          <Authors
            authors={story.authors}
            clazzName="author-name-image"
            lastUpdatedDateAndTime={lastUpdatedDateAndTime}
            isPVExclusive={isPVExclusive}
          />
        )}
        <div styleName="long-line"></div>
      </div>
    );
  }

  return (
    <div styleName="wrapper">
      {!isSponsoredStory && (
        <div id="author-wrapper-id">
          <Authors
            authors={story.authors}
            clazzName="author-name-image"
            lastUpdatedDateAndTime={lastUpdatedDateAndTime}
          />
        </div>
      )}
      <div styleName={` ${isSticky ? "alternate-wrapper" : ""}`}></div>
      <div
        styleName="timestamp-share-wrapper"
        className={`sticky-div ${isSticky ? "sticky" : ""}`}
        ref={timestampShareRef}
        style={{ width: timestampShareWidth }}
      >
        <div styleName="author-resize-wrapper-contents">
          <FollowUs />
          <div styleName="comment-share-wrapper">
            {story["story-template"] !== "live-blog" && (
              <CommentsCount story={story} showCommentText={false} renderPortal={true} />
            )}
            <div styleName="share" ref={shareRef}>
              <button onClick={sharingHandler}>
                <SvgIconHandler type="storyshare" height="42px" width="41.48px" />
              </button>
              <div styleName="share-options">{showSharingOptions && <SocialMediaShare story={story} />}</div>
            </div>
          </div>
          {/* <Resizer story={story} /> */}
        </div>
      </div>
    </div>
  );
};

AuthorAndTimeStamp.propTypes = {
  story: object,
  isPVExclusive: bool,
  isSponsoredStory: bool
};

AuthorAndTimeStamp.defaultProps = {
  isPVExclusive: false,
  isSponsoredStory: false
};
export default AuthorAndTimeStamp;
