import React from "react";
import { object, number } from "prop-types";

import StoryHeader from "../story-header";
import StoryElements from "../story-elements";
import StorySideBar from "../story-sidebar";
import StorySeparator from "../../atoms/story-separator";

import "./podcast.m.css";

const PodCastStoryTemplate = ({ story, index }) => {
  const isPodcast = story["story-template"] === "video-playlist" || story["story-template"] === "podcast";
  return (
    <>
      {index > 0 && <StorySeparator />}
      <div styleName="story-wrapper">
        <div className="story-section">
          <div>
            <StoryHeader story={story} isPodCast showHeroImage={false} />
            <StoryElements story={story} cards={story.cards} index={index} isPodcast={isPodcast} />
          </div>
        </div>

        <div className="side-story-section hide-mobile">
          <StorySideBar index={index} story={story} />
        </div>
      </div>
    </>
  );
};

PodCastStoryTemplate.propTypes = {
  story: object,
  index: number
};

const PodCastStory = (props) => {
  return <PodCastStoryTemplate story={props.story} index={props.index} />;
};

PodCastStory.propTypes = {
  story: object,
  index: number
};

export default PodCastStory;
