import React from "react";
import get from "lodash/get";

import TimeIcon from "../../../atoms/icons/time-icon";
import Sunrise from "../../../atoms/icons/sunrise";
import Sunrset from "../../../atoms/icons/sunset";

import "./panchanga.m.css";

const PanchangaView = ({ story }) => {
  const title = get(story, ["cards", "0", "story-elements", "0", "text"], "");

  const panchangaSunrise = get(story, ["metadata", "panchanga-sunrise"], "");
  const panchangaSunset = get(story, ["metadata", "panchanga-sunset"], "");
  const panchangaRahuKal = get(story, ["metadata", "panchanga-rahukal"], "");
  const panchangaGlikal = get(story, ["metadata", "panchanga-gulikal"], "");
  const panchangaDay = get(story, ["metadata", "panchanga-day"], "");
  const panchangaStar = get(story, ["metadata", "panchanga-star"], "");
  const panchangaSamvatsar = get(story, ["metadata", "panchanga-samvatsar"], "");
  const panchangaaayan = get(story, ["metadata", "panchanga-aayan"], "");
  const panchangaRitu = get(story, ["metadata", "panchanga-ritu"], "");
  const panchangaMassa = get(story, ["metadata", "panchanga-maasa"], ""); // #FD2717

  return (
    <div styleName="wrapper">
      <div styleName="title">{title.replace(/<\/?[^>]+(>|$)/g, "")}</div>
      <div styleName="panchanga-time">
        <div styleName="time-wrapper">
          <div styleName="time-key-wrapper">
            <Sunrise />
            <span styleName="time-key">ಸೂರ್ಯೋದಯ:</span>
          </div>
          <span styleName="time-value">{panchangaSunrise}</span>
        </div>
        <div styleName="time-wrapper">
          <div styleName="time-key-wrapper">
            <Sunrset />
            <span styleName="time-key">ಸೂರ್ಯಾಸ್ತ:</span>
          </div>
          <span styleName="time-value">{panchangaSunset}</span>
        </div>
        <div styleName="time-wrapper">
          <div styleName="time-key-wrapper">
            <TimeIcon />
            <span styleName="time-key">ರಾಹು ಕಾಲ:</span>
          </div>
          <span styleName="time-value">{panchangaRahuKal}</span>
        </div>
        <div styleName="time-wrapper">
          <div styleName="time-key-wrapper">
            <TimeIcon color="#FD2717" />
            <span styleName="time-key">ಗುಳಿ ಕಾಲ:</span>
          </div>
          <span styleName="time-value">{panchangaGlikal}</span>
        </div>
      </div>
      <ul styleName="details">
        <li>
          <span styleName="pancganga-key">ವಾರ</span>
          <span styleName="panchanga-value">{panchangaDay}</span>
        </li>
        <li>
          <span styleName="pancganga-key">ನಕ್ಷತ್ರ</span>
          <span styleName="panchanga-value">{panchangaStar}</span>
        </li>
        <li>
          <span styleName="pancganga-key">ಸಂವತ್ಸರ</span>
          <span styleName="panchanga-value">{panchangaSamvatsar}</span>
        </li>
        <li>
          <span styleName="pancganga-key">ಆಯನ</span>
          <span styleName="panchanga-value">{panchangaaayan}</span>
        </li>
        <li>
          <span styleName="pancganga-key">ಋತು</span>
          <span styleName="panchanga-value">{panchangaRitu}</span>
        </li>
        <li>
          <span styleName="pancganga-key">ಮಾಸ</span>
          <span styleName="panchanga-value">{panchangaMassa}</span>
        </li>
      </ul>
    </div>
  );
};

export default PanchangaView;
