import React, { useEffect, useState, useRef } from "react";
import { object } from "prop-types";
import { ResponsiveImage, Link } from "@quintype/components";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import SocialMediaShare from "../social-media-share";
import { extractTextFromHtml } from "../../helper/utils";

import "./imagecaption.m.css";

function ImagewithCaption({ element, story, index }) {
  const [toggleSharing, setToggleSharing] = useState(false);
  const shareRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleSharing && !shareRef.current.contains(event.target)) {
        setToggleSharing(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [toggleSharing]);

  const sharingHandler = () => {
    setToggleSharing(!toggleSharing);
  };

  useEffect(() => {
    if (window) {
      window.dataLayer.push({
        event: "photoStory"
      });
    }
  }, []);

  return (
    <>
      <div styleName="wrapper" className="photo-image">
        <div styleName="share-icon">
          <button styleName="share" onClick={sharingHandler}>
            <SvgIconHandler type="shareicon" />
          </button>
          <div styleName="share-options">{toggleSharing && <SocialMediaShare story={story} />}</div>
        </div>
        {element?.hyperlink ? (
          <Link href={element?.hyperlink}>
            <ResponsiveImage
              slug={decodeURIComponent(element["image-s3-key"])}
              metadata={element.metadata}
              widths={[420, 1040, 1600]}
              defaultWidth={1040}
              alt={extractTextFromHtml(element?.title) || element["image-attribution"] || ""}
            />
          </Link>
        ) : (
          <ResponsiveImage
            slug={decodeURIComponent(element["image-s3-key"])}
            metadata={element.metadata}
            widths={[420, 1040, 1600]}
            defaultWidth={1040}
            alt={extractTextFromHtml(element?.title) || element["image-attribution"] || ""}
          />
        )}
      </div>
      <div styleName="image-caption-wrapper">
        <span styleName="image-caption-text" dangerouslySetInnerHTML={{ __html: element.title }} />
        <span styleName="image-attribute" dangerouslySetInnerHTML={{ __html: element["image-attribution"] }} />
      </div>
    </>
  );
}

ImagewithCaption.propTypes = {
  element: object,
  story: object
};

export default ImagewithCaption;
