import React from "react";
import get from "lodash/get";

import "./fact-check.m.css";

const FactCheckView = ({ story }) => {
  const factCheckHeadline = get(story, ["metadata", "fact-check-name"], "");
  const factCheckAnswer = get(story, ["metadata", "fact-check-answer"], "");
  const factCheckText = get(story, ["metadata", "fact-check-text"], "");

  return (
    <div styleName="wrapper">
      <div styleName="header-text">
        <div styleName="fact-q">
          <div styleName="text">ಕ್ಲೇಮು</div>
          <div styleName="q-headline">{factCheckHeadline}</div>
        </div>
        <div styleName="fact-a">
          <div styleName="text">ನಿರ್ಣಯ:</div>
          <div styleName="a-headline">{factCheckAnswer}</div>
        </div>
      </div>
      <div>
        <span styleName="detail-text text1">ಸರಿಯಾದ ಮಾಹಿತಿ:</span>
        <span styleName="detail-text">{factCheckText}</span>
      </div>
    </div>
  );
};

export default FactCheckView;
