import React from "react";
import "./q-and-a.m.css";

function getHTMLfromString(str) {
  const theObj = { __html: str };
  return theObj;
}

const Question = ({ questionElement, interviewer }) => {
  return (
    <div styleName="question_container">
      <span styleName="question_icon">Q</span>
      <div styleName="question">
        <div dangerouslySetInnerHTML={questionElement} />
        {interviewer && <span styleName="attribute">({interviewer})</span>}
      </div>
    </div>
  );
};

const Answer = ({ answerElement, interviewee }) => {
  return (
    <div styleName="answer_container">
      <span styleName="answer_icon">A</span>
      <div styleName="answer">
        <div dangerouslySetInnerHTML={answerElement} />
        {interviewee && <span styleName="attribute">({interviewee})</span>}
      </div>
    </div>
  );
};

const QandA = ({ story, element, subtype }) => {
  const { answer, question, interviewee, interviewer } = element.metadata;

  const answerEle = getHTMLfromString(answer);
  const questionEle = getHTMLfromString(question);
  const qoraEle = getHTMLfromString(element.text);

  if (subtype === "answer") {
    return <Answer answerElement={qoraEle} interviewee={interviewee?.name} />;
  }

  if (subtype === "question") {
    return <Question questionElement={qoraEle} interviewer={interviewer?.name} />;
  }

  return (
    <div>
      <Question questionElement={questionEle} interviewer={interviewer?.name} />
      <Answer answerElement={answerEle} interviewee={interviewee?.name} />
    </div>
  );
};

export default QandA;
