import React, { useState, useEffect, useRef } from "react";

import "./truncated-text.m.css";

const TruncatedText = ({ story }) => {
  const contentRef = useRef(null);
  const [readMore, setReadMore] = useState(false);
  const showWholeSubHeadline = readMore ? "line-clamp-3" : "line-clamp-unset";

  useEffect(() => {
    countLines();
  }, []);

  function countLines() {
    const el = contentRef.current;
    const divHeight = el.offsetHeight;
    const lineHeight = parseInt(window.getComputedStyle(el).lineHeight);
    const lines = divHeight / lineHeight;
    if (lines >= 4) setReadMore(true);
  }

  return (
    <div styleName="subheadline-container" id="content" ref={contentRef}>
      <div styleName={`subheadline-wrapper ${showWholeSubHeadline}`}>{story.subheadline}</div>
      {readMore && (
        <div styleName="readmore-container">
          ...
          <span onClick={() => setReadMore(false)}>Read More</span>
        </div>
      )}
    </div>
  );
};

export default TruncatedText;
