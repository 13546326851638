/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { object } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { StoryElement } from "@quintype/components";

import "./embed-element.m.css";

export const getPrimaryEmbed = (story) => {
  const { cards } = story;
  const primaryEmbed = [];
  cards.forEach((card) => {
    card["story-elements"].forEach((element) => {
      if (element.type === "jsembed") {
        primaryEmbed.push(element);
      }
    });
  });
  return primaryEmbed[0];
};

const EmbedElementCard = ({ story, publisherName }) => {
  const [showEmbed, setShowEmbed] = useState(false);
  const embedElement = getPrimaryEmbed(story);
  const storyTemplate = story && story["story-template"];

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowEmbed(true);
      console.log("Setting showEmbed to true");
    }, 4000);

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div styleName={storyTemplate === "podcast" ? "wrapper podcast-wrapper" : "wrapper"}>
      {showEmbed && embedElement ? (
        <StoryElement element={embedElement} story={story} publisherName={publisherName} />
      ) : null}
    </div>
  );
};

EmbedElementCard.propTypes = {
  story: object
};

const mapStateToProps = (state) => ({
  publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null)
});

export default connect(mapStateToProps, null)(EmbedElementCard);
